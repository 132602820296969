import storage from 'store'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@/store/mutation-types'
import { getUserInfo, getUsernamePasswordToken, refreshToken } from '@/api/oauth/account'
import {getCurrentCompany} from '@/api/res-company'

const user = {
  state: {
    access_token: '',
    refresh_token: '',
    permissions: [],
    info: null,
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token
    },
    SET_REFRESH_TOKEN: (state, refresh_token) => {
      state.refresh_token = refresh_token
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },

  },
  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        const {
          username,
          password,
          loginType
        } = userInfo
        getUsernamePasswordToken(username, password, loginType)
          .then((res) => {
            const accessToken = res.accessToken
            const refreshToken = res.refreshToken
            storage.set(ACCESS_TOKEN, accessToken, 7 * 24 * 60 * 60 * 1000)
            storage.set(REFRESH_TOKEN, refreshToken, 7 * 24 * 60 * 60 * 1000)
            commit('SET_ACCESS_TOKEN', ACCESS_TOKEN)
            commit('SET_REFRESH_TOKEN', REFRESH_TOKEN)
            resolve(res)
          })
          .catch((e) => {
            reject(e && e.data)
          })
      })
    },
    RefreshToken ({ commit }, refresh_token) {
      return refreshToken(refresh_token).then((res) => {
        const accessToken = res.access_token
        const refreshToken = res.refresh_token
        const scope = res.scope
        const id_token = res.id_token
        const token_type = res.token_type
        const expires_in = res.expires_in
        storage.set(ACCESS_TOKEN, accessToken, 7 * 24 * 60 * 60 * 1000)
        storage.set(REFRESH_TOKEN, refreshToken, 7 * 24 * 60 * 60 * 1000)
        commit('SET_ACCESS_TOKEN', accessToken)
        commit('SET_REFRESH_TOKEN', refreshToken)
        return accessToken
      })
    },
    // 获取用户信息
    async GetInfo ({ commit }) {
      const userInfo = await getUserInfo()
      console.log(userInfo)
      if (!userInfo) {
        commit('SET_INFO', null)
        commit('SET_PERMISSIONS', [])
        storage.remove(ACCESS_TOKEN)
        storage.remove(REFRESH_TOKEN)
        throw '您的账号已被删除'
      }
      commit('SET_INFO', userInfo)
      commit('SET_PERMISSIONS', ['user'])
      return userInfo
    },
    // 登出
    Logout ({ commit }) {
      return new Promise((resolve) => {
        commit('SET_INFO', null)
        commit('SET_PERMISSIONS', [])
        storage.remove(ACCESS_TOKEN)
        storage.remove(REFRESH_TOKEN)
        resolve()
      })
    },
    async RevokeToken ({ commit }) {
      commit('SET_INFO', null)
      commit('SET_PERMISSIONS', [])
      storage.remove(ACCESS_TOKEN)
      storage.remove(REFRESH_TOKEN)
    },
  }
}

export default user
