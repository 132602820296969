import { PortalRequest as request } from "@/utils/request";

/**
 * 当前登录用户企业
 * @param data
 * @returns {AxiosPromise}
 */
export const getCurrentCompany = () => {
  return request({
    url: "/join-company/get-default-company",
    method: "get",
  });
};

/**
 * h5默认企业
 * @returns {*}
 */
export const getH5CurrentCompany = () => {
  return request({
    url: "/join-company/get-h5-default-company",
    method: "get",
  });
};




/**
 * 创建企业
 * @param data
 * @returns {AxiosPromise}
 */
export const createCompany = (data) => {
  return request({
    url: "/res-company/save",
    method: "post",
    data,
  });
};
/**
 * 编辑企业信息
 * @param {*} id
 * @param {*} data
 * @returns
 */
export const editCompany = (id,data) => {
  return request({
    url: `/res-company/${id}/update`,
    method: "post",
    data,
  });
};

/**
 * 提交认证资料
 * @param data
 * @returns {AxiosPromise}
 */
export const  applyCompanyCertification = (data) => {
  return request({
    url: "/res-company/save",
    method: "post",
    data,
  });
};
/**
 * 修改企业logo
 * @param companyLogoAttmId
 * @returns {AxiosPromise}
 */
export const changeLogo = (companyLogoAttmId) => {
  return request({
    url: "/res-company/change-logo",
    method: "post",
    data: { companyLogoAttmId },
  });
};

// 用户是否为成员管理审核人
export const getIsVerifier = () => {
  return request({
    url: `/join-company/isVerifier`,
    method: "get",
  });
};

// 获取企业信息
export const getCompanyInfo = (id) => {
  return request({
    url: `/res-company/info/${id}`,
    method: "get",
  });
};

// 加入企业
export const getAddCompany = (id) => {
  return request({
    url: `/join-company/${id}/apply-join?isH5Create=true`,
    method: "get",
  });
};

// 用户是否为企业创建者
export const getIsCreater = (id) => {
  return request({
    url: `/join-company/${id}/is-owner`,
    method: "get",
  });
};



