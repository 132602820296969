import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import { VueAxios } from './utils/request'
import VueWechatTitle from 'vue-wechat-title'

import './utils/lazy_use'
import 'amfe-flexible'
import 'ant-design-vue/dist/antd.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import moment from 'moment'
import VueLazyload from 'vue-lazyload'
import './assets/css/base.css'

import sensors from "@/utils/sensors.js";
Vue.prototype.$sensors = sensors;


import wechatUtil from './utils/wechat'


Vue.prototype.wechatUtil = wechatUtil

Vue.config.productionTip = false
Vue.use(VueAxios)
Vue.use(Vant)
Vue.use(VueWechatTitle)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/img/kctp.png'),
  loading: require('@/assets/img/kctp.png'),
  attempt: 1
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('dict', (value, code) => {
  if (!value) {
    return null
  }
  return store.getters.dictText(code, value)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD') {
  return dataStr && moment(dataStr).format(pattern)
})

Vue.filter('lastTime', function (dataStr) {
  return moment(dataStr).fromNow()
})

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.substring(0, 3) + "****" + value.substring(value.length - 4);
});


