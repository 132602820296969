import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";

import getters from "./getters";
import dict from "./modules/dict";
import tenant from "./modules/tenant";
import settings from "@/store/modules/settings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dict,
    tenant,
    settings,
  },
  state: {
    infoList:'',
  },
  mutations: {
    set_infoList:(state,infoList)=>{
      state.infoList=infoList
    }
  },
  actions: {},
  getters,
});
