import { OauthRequest, PortalRequest } from '@/utils/request'

export const getUsernamePasswordToken = (username, password, loginType) => {
  return PortalRequest({
    url: '/auth/h5Login',
    method: 'post',
    params: {
      redirect_uri: process.env.VUE_APP_H5_URI,
      service: process.env.VUE_APP_H5_URI,
      username,
      password,
      loginType
    }
  })
}
/**
 * 获取当前登录用户基本信息
 */
export const getUserInfo = () => {
  // const token = storage.get(ACCESS_TOKEN);
  return PortalRequest({
    url: '/res-user/me',
    method: 'get'
    // params: { token, redirect_uri: store.getters.redirectUri },
  })
}

/**
 * 获取当前登录用户基本信息
 */
export const sendLoginMobile = (mobile) => {
  return OauthRequest({
    url: `/common/sms-send/${mobile}`,
    method: 'post',
    params: {
      smsType: 'register'
    }
  })
}

export const refreshToken = (token) => {
  return PortalRequest({
    url: '/auth/refreshToken',
    method: 'post',
    params: { token, redirect_uri: store.getters.redirectUri }
  })
}
