import router from './router'
import store from './store'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { addViewed, setPVAndUV } from '@/api/common'

const loginRoutePath = '/login'// /login
const defaultRoutePath = '/'

router.beforeEach(async (to, from, next) => {
  console.log('beforeEach', to.path)
  const promises = []
  // if (!store.getters.tenant) {
  //   promises.push(store.dispatch("LoadCurrentTenant"));
  // }
  // if (!store.getters.settings) {
  //   promises.push(store.dispatch('LoadSettings'))
  // }
  await Promise.all(promises)
  if (to.path === '/logout') {
    await store.dispatch('RevokeToken')
    next({ path: defaultRoutePath })
    return
  }
  const ignoreList = [loginRoutePath, '/register', '/register/repeat', '/register/success', '/register/success-login']
  if (ignoreList.indexOf(to.path) !== -1) {
    if (!to.query.redirect_uri) {
      let redirectUri = ''
      if (from.path === '/activity') {
        redirectUri = '/register/prompt'
      } else if (ignoreList.indexOf(from.path) !== -1) {
        redirectUri = from.query.redirect_uri
      } else {
        redirectUri = from.fullPath
      }
      next({ path: to.path, query: { ...to.query, redirect_uri: redirectUri } })
    } else {
      next()
    }
    return
  }

  const token = storage.get(ACCESS_TOKEN)
  // 目标路由是否需要登录
  const needLogin = (to) => to.meta && to.meta.permissions && to.meta.permissions.length && to.meta.permissions.indexOf('user') !== -1
  // 如果token不存在
  if (!token) {
    if (needLogin(to)) {
      next({ path: loginRoutePath, query: { ...to.query, redirect_uri: to.fullPath } })
    } else {
      next()
    }
    // 如果token存在并且当前用户信息已存在
  } else if (store.getters.userInfo) {
    next()
    // 如果token存在，并且用户信息不存在
  } else {
    // 获取用户信息
    try {
      await store.dispatch('GetInfo')
      next()
    } catch (e) {
      // await store.dispatch("RevokeToken");
      if (needLogin(to)) {
        next({ path: loginRoutePath, query: { ...to.query, redirect_uri: to.fullPath } })
      } else {
        next()
      }
    }
  }
})

router.afterEach((to, from) => {
  if (to.path === '/solution/info') {
    addViewed('solution', to.query.id)
  } else if (to.path === '/course/info') {
    addViewed('course', to.query.id)
  }
})
