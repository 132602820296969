import { getOpenId } from '@/api/common'

export default {
  saveWechatInfo: function (code, openId) {

    if (null != code && code.length > 0 && (null == openId || openId.length == 0)) {
      getOpenId({ code }).then(res => {
        if (res.openId) {
          localStorage.setItem('openId', res.openId)
        }
      })
    }
  }
}
