import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush=VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/page/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/page/register')
  },
  {
    path: '/register/repeat',
    name: 'repeat',
    component: () => import('@/page/register/repeat')
  },
  {
    path: '/register/success',
    name: 'success',
    component: () => import('@/page/register/success')
  },
  {
    path: '/register/success-login',
    name: 'successLogin',
    component: () => import('@/page/register/SuccessLogin')
  },
  {
    path: '/register/prompt',
    name: 'prompt',
    component: () => import('@/page/register/prompt')
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/page/activity')
  },
  {
    path: '/course/video',
    name: 'courseVideo',
    component: () => import('@/page/course/video')
  },
  {
    path: '/personal_result',
    name: 'personal_result',
    component: () => import('@/page/verified/personal_result'),
    meta: {
      permissions: ['user']
    }
  },
  {
    path: '/verified/company_info',
    name: 'company_info',
    component: () => import('@/page/verified/company_info'),
    meta: {
      permissions: ['user']
    }
  },
  {
    path: '/verified/company_result',
    name: 'company_result',
    component: () => import('@/page/verified/company_result'),
    meta: {
      permissions: ['user']
    }
  },
  {
    path: '/',
    component: () => import('@/page/layout'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/page/home')
      },
      {
        path: '/solution',
        name: 'solution',
        component: () => import('@/page/solution')
      },
      {
        path: '/solution/list',
        name: 'solutionList',
        component: () => import('@/page/solution/list')
      },
      {
        path: '/solution/info',
        name: 'solutionInfo',
        component: () => import('@/page/solution/info')
      },
      {
        path: '/solution/digitizing',
        name: 'digitizing',
        component: () => import('@/page/solution/digitizing')
      },
      {
        path: '/solution/internet_things',
        name: 'internet_things',
        component: () => import('@/page/solution/internet_things')
      },
      {
        path: '/solution/ordinary_program',
        name: 'ordinary_program',
        component: () => import('@/page/solution/ordinary_program')
      },
      {
        path: '/solution/searchList',
        name: 'solutionSearchList',
        component: () => import('@/page/solution/searchList')
      },
      {
        path: '/questionnaire',
        name: 'questionnaire',
        component: () => import('@/page/questionnaire')
      },
      {
        path: '/questionnaire/info',
        name: 'questionnaireinfo',
        component: () => import('@/page/questionnaire/info')
      },
      {
        path: '/consult',
        name: 'consult',
        component: () => import('@/page/consult')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/page/product')
      },
      {
        path: '/product/iotInfo',
        name: 'iotInfo',
        component: () => import('@/page/product/iotInfo')
      },
      {
        path: '/product/productInfo',
        name: 'productInfo',
        component: () => import('@/page/product/productInfo')
      },
      {
        path: '/product/list',
        name: 'productDevList',
        component: () => import('@/page/product/list')
      },
      {
        path: '/product/productList',
        name: 'productList',
        component: () => import('@/page/product/productList')
      },
      {
        path: '/applyDev',
        name: 'applyDev',
        component: () => import('@/page/applyDev'),
        meta: {
          permissions: ['user'],
          footer: false
        }
      }, {
        path: '/demandMove',
        name: 'demandMove',
        component: () => import('@/page/demandMove'),
        meta: {
          permissions: ['user'],
          footer: false
        }
      }, {
        path: '/demandMove/add',
        name: 'demandMoveAdd',
        component: () => import('@/page/demandMove/add'),
        meta: {
          permissions: ['user'],
          footer: false
        }
      }, {
        path: '/course',
        name: 'course',
        component: () => import('@/page/course')
      }, {
        path: '/course/search',
        name: 'courseSearch',
        component: () => import('@/page/course/search')
      }, {
        path: '/course/info',
        name: 'courseInfo',
        component: () => import('@/page/course/info')
      },
      {
        path: '/wordForm',
        name: 'wordForm',
        component: () => import('@/page/wordForm'),
        meta: {
          footer: false
          // permissions: ['user']
        }
      }, {
        path: '/digitizing',
        name: 'digitizing',
        component: () => import('@/page/digitizing')
      }, {
        path: '/myDev',
        name: 'myDev',
        component: () => import('@/page/myDev'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      }, {
        path: '/myConsult',
        name: 'myConsult',
        component: () => import('@/page/myConsult'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      }, {
        path: '/myConsult/info',
        name: 'myConsultInfo',
        component: () => import('@/page/myConsult/info'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      }, {
        path: '/myDemand',
        name: 'myDemand',
        component: () => import('@/page/myDemand'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      }, {
        path: '/myDemand/info',
        name: 'myDemandInfo',
        component: () => import('@/page/myDemand/info'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      }, {
        path: '/myCourse',
        name: 'myCourse',
        component: () => import('@/page/myCourse'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      }, {
        path: '/myQuestionnaire',
        name: 'myQuestionnaire',
        component: () => import('@/page/myQuestionnaire'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/protocol',
        name: 'protocol',
        component: () => import('@/page/protocol')
      },
      {
        path: '/protocol/serve',
        name: 'protocolServe',
        component: () => import('@/page/protocol/serve')
      },
      {
        path: '/verified',
        name: 'verified',
        component: () => import('@/page/verified'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/personal_info',
        name: 'personal_info',
        component: () => import('@/page/verified/personal_info'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/verified_personal',
        name: 'verified_personal',
        component: () => import('@/page/verified/verified_personal'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/verified/company',
        name: 'company',
        component: () => import('@/page/verified/company'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/verified/create_company',
        name: 'create_company',
        component: () => import('@/page/verified/create_company'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/verified/association_company',
        name: 'association_company',
        component: () => import('@/page/verified/association_company'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/verified/certification_company',
        name: 'certification_company',
        component: () => import('@/page/verified/certification_company'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },
      {
        path: '/verified/uerinfo_company',
        name: 'uerinfo_company',
        component: () => import('@/page/verified/uerinfo_company'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },

      {
        path: '/verified/company_list',
        name: 'company_list',
        component: () => import('@/page/verified/company_list'),
        meta: {
          footer: false,
          permissions: ['user']
        }
      },

    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
