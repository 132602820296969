import Vue from "vue";

// base library
import {
  Input,
  InputNumber,
  Button,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  Row,
  Col,
  FormModel,
  Cascader
} from "ant-design-vue";

Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Button);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Select);
Vue.use(Card);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Row);
Vue.use(Col);
Vue.use(Cascader);

process.env.NODE_ENV !== "production" &&
  console.warn("[antd-pro] NOTICE: Antd use lazy-load.");
