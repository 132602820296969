import { getCurrentTenant } from "@/api/common";

export default {
  state: {
    tenant: null,
  },
  mutations: {
    SET_TENANT(state, tenant) {
      state.tenant = tenant;
    },
  },
  actions: {
    LoadCurrentTenant({ commit }) {
      return new Promise((resolve) => {
        getCurrentTenant().then((data) => {
          if (data) {
            document.title = data.tenantName;
            if (data.iconAttmId) {
              let fav = document.querySelector('link[rel="icon"]');
              if (!fav) {
                fav = document.createElement("link");
                fav.setAttribute("rel", "shortcut icon");
                fav.setAttribute("type", "image/x-icon");
                document.head.appendChild(fav);
              }
              fav.href = `${process.env.VUE_APP_API_PORTAL_URL}/file/image/${data.iconAttmId}`;
            }
          }
          commit("SET_TENANT", data);
          resolve(data);
        });
      });
    },
  },
};
